import React from 'react';
import config from './lib/config';
import URI from 'urijs';
import sessionStorageUtil from './lib/session-storage-util';
import PropTypes from 'prop-types';
import { logDegradedStateErrorEvent, logDegradedStateEvent } from './lib/amplitude-utils';
import { logger, flushLogs } from './lib/logger';
import { DegradedStateAttemptedError, DegradedStateFailureError } from './lib/errors';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { errorMessage: '', degradeToOktaRedirectUrl: null, transformedError: null };
  }

  static getDerivedStateFromError(error) {
    const httpStatusCode = error.statusCode;
    const errorDetails = {};
    // for userErrors from iam-core which gives custom message
    if (error && httpStatusCode === 400 && error.message){
      errorDetails.errorMessage = error.message;
    } else if (error.name === 'ValidationError'){
      errorDetails.errorMessage = error.message;
    } else if (error.name === 'RedirectableError' && error.redirectUrl) {
      // eslint-disable-next-line camelcase
      errorDetails.redirectUrl = new URI(error.redirectUrl).query({
        error: 'invalid_request', error_description: error.message,
      })
        .toString();
    } else {
      // For everything else, degrade to Okta
      const originalAuthUrl = sessionStorageUtil.getOriginalAuthUrl();
      if (originalAuthUrl) {
        errorDetails.transformedError = new DegradedStateAttemptedError(error);
        const oktaURL = new URI(config.oktaURL);
        const authUrl = new URI(originalAuthUrl);
        const degradeToOktaRedirectUrl =
          oktaURL.segment(authUrl.path())
            .query(authUrl.query())
            .toString();
        errorDetails.redirectUrl = degradeToOktaRedirectUrl;

      } else {
        errorDetails.transformedError = new DegradedStateFailureError(error);
        errorDetails.errorMessage = 'Unexpected error occurred while signing in.';
      }
    }
    return errorDetails;
  }

  async componentDidCatch(error, errorInfo) {
    sessionStorageUtil.removeOriginalAuthUrl();
    const logPromises = [];
    const { transformedError, redirectUrl } = this.state;
    if (transformedError) {
      logger.error(JSON.stringify(transformedError));
      if (transformedError.name === 'DegradedStateAttemptedError') {
        // eslint-disable-next-line
        logPromises.push(logDegradedStateEvent({ error_message: error.message }));
      } else {
        // eslint-disable-next-line
        logPromises.push(logDegradedStateErrorEvent({ error_message: error.message }));
      }
      logPromises.push(flushLogs());
      await Promise.all(logPromises);
    }
    if (redirectUrl) {
      window.location.assign(redirectUrl);
    }
  }

  render() {
    const { errorMessage, redirectUrl } = this.state;
    if (errorMessage) {
      return (
        <div id = 'error-boundary'>
          {errorMessage}
        </div>
      );
    }
    if (redirectUrl) {
      return <div/>;
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};
