/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import { initAuthorize } from './lib/scripts/page-specific/oauth2/authorize';
import config from './lib/config';
import { useOktaAuth } from '@okta/okta-react';
import { setAndGetLwaUniqId } from './lib/cookie-utils';

const customLinksAthena =
  [
    {
      href: 'https://www.athenahealth.com/resources/patient-facing-applications-terms-of-use',
      text: 'Terms of Use',
      target: '_blank',
    },
    {
      href: 'https://www.athenahealth.com/resources/privacy-notice-for-patient-facing-applications',
      text: 'Privacy Policy',
      target: '_blank',
    },
  ];

const defaultProps = {
  iamCoreUrl: config.iamCoreUrl,
  anetUserDomain: config.anetUserDomain,
  oktaUrl: config.oktaURL,
  supportEnv: '',
  forgotPasswordAthena: 'signin/athena-forgot-password',
  customLinksAthena: JSON.stringify(customLinksAthena),
  redirectUri: config.redirectUri,
  renderWidgetFunction: initAuthorize,
  features: JSON.stringify({
    idpDiscovery: config.oktaIdpDiscovery,
  }),
  consentOktaUrl: config.oktaURL,
  amplitudeApiKey: config.amplitudeApiKey,
  scope: config.scopes.join(' '),
};
export default function OktaSignInWidget(props) {
  props = { ...defaultProps, ...props };
  const { oktaAuth } = useOktaAuth();
  const lwaUniqId = setAndGetLwaUniqId();
  React.useEffect(() => {
    props.renderWidgetFunction.call(undefined, oktaAuth);
    return () => {
      if (window.oktaSignIn) {
        window.oktaSignIn.off();
        window.oktaSignIn.remove();
      }
    };
  }, []);

  return (
    <div id="okta-login-container" data-okta-url={props.oktaUrl}
      data-consent-okta-url={props.consentOktaUrl}
      data-athenanet={props.athenanet}
      data-amplitude-user-id={props.userId}
      data-amplitude-device-id={props.amplitudeDeviceId}
      data-authorize-redirect-url={props.authorizeRedirectUrl}
      data-proxied-authorize-url={props.proxiedAuthorizeUrl}
      data-forgot-password-athena={props.forgotPasswordAthena}
      data-proxied-authorize-values={props.proxiedAuthorizeValues}
      data-support-env={props.supportEnv}
      data-anet-user-domain={props.anetUserDomain}
      data-create-account={props.createAccount}
      data-prompt={props.prompt}
      data-username={props.username}
      data-session-token={props.sessionToken}
      data-state-token={props.stateToken}
      data-application-id={props.clientId}
      data-access-token={props.accessToken}
      data-id-token={props.idToken}
      data-redirect-uri={props.redirectUri}
      data-language={props.language}
      data-logo-url={props.logoUrl}
      data-logo-text={props.logoText}
      data-post-authn-routes={props.postAuthnRoutes}
      data-iam-core-url={props.iamCoreUrl}
      data-custom-links-athena={props.customLinksAthena}
      data-custom-links={props.customLinksAthena}
      data-i18n={props.i18n}
      data-display-name={props.displayName}
      data-scope={props.scope}
      data-user-state={props.userState}
      data-sanitize-info={props.sanitizeInfo}
      data-amplitude-api-key={props.amplitudeApiKey}
      data-test-app={props.testApp}
      data-workflow={props.workflow}
      data-idp={props.idp}
      data-features={props.features}
      data-workflow-type={props.workflowType}
      data-new-user={props.newUser}
      data-server-time={props.serverTime}
      data-lwa-uniq-id={lwaUniqId}
      data-device-id={props.deviceId}
      data-relogin={props.relogin}
      data-workflow-handler-endpoint={props.workflowHandlerEndpoint}
      data-aud={props.aud}
      data-state={props.state}
      data-email-token={props.emailToken}
      data-hide-reset-account-option={props.hideResetAccountOption}
      data-app-clientid={props.clientId}
      data-show-terms-text-on-login={props.showTermsTextOnLogin}
      data-show-terms-text={props.showTermsText}
      data-iframe-url={props.loginFrameUrl}
      data-user-app-type-mismatch-error={props.userAppTypeMismatchError}
      data-email-token-logged-in-user-mismatch-error={props.emailTokenLoggedInUserMismatchError}
      data-user-namespace={props.userNamespace}
      data-manage-profile-error={props.manageProfileError}
      data-okta-org-id={props.oktaOrgId}
      data-email-token-user-registered-warning={props.emailTokenUserRegisteredWarning}
      data-show-set-password-description={props.showSetPasswordDescription}
      data-skip-patient-summary={props.skipPatientSummary}
      data-app-type={props.appType}
      data-hide-remember-me={props.hideRememberMe}
      data-user-mfa-policy-tier={props.userMfaPolicyTier}
      data-context-mfa-policy-tier={props.contextMfaPolicyTier}
    >
    </div>
  );
}

OktaSignInWidget.propTypes = {
  oktaUrl: PropTypes.string,
  consentOktaUrl: PropTypes.string,
  athenanet: PropTypes.string,
  userId: PropTypes.string,
  amplitudeDeviceId: PropTypes.string,
  authorizeRedirectUrl: PropTypes.string,
  proxiedAuthorizeUrl: PropTypes.string,
  forgotPasswordAthena: PropTypes.string,
  proxiedAuthorizeValues: PropTypes.string,
  supportEnv: PropTypes.string,
  anetUserDomain: PropTypes.string,
  createAccount: PropTypes.bool,
  prompt: PropTypes.string,
  username: PropTypes.string,
  sessionToken: PropTypes.string,
  stateToken: PropTypes.string,
  accessToken: PropTypes.string,
  rewriteUrl: PropTypes.string,
  idToken: PropTypes.string,
  redirectUri: PropTypes.string,
  language: PropTypes.string,
  logoUrl: PropTypes.string,
  logoText: PropTypes.string,
  postAuthnRoutes: PropTypes.string,
  iamCoreUrl: PropTypes.string,
  customLinksAthena: PropTypes.string,
  i18n: PropTypes.string,
  displayName: PropTypes.string,
  scope: PropTypes.string,
  userState: PropTypes.string,
  sanitizeInfo: PropTypes.string,
  amplitudeApiKey: PropTypes.string,
  testApp: PropTypes.string,
  workflow: PropTypes.string,
  idp: PropTypes.string,
  features: PropTypes.string,
  workflowType: PropTypes.string,
  newUser: PropTypes.bool,
  serverTime: PropTypes.number,
  deviceId: PropTypes.string,
  relogin: PropTypes.bool,
  workflowHandlerEndpoint: PropTypes.string,
  aud: PropTypes.string,
  state: PropTypes.string,
  emailToken: PropTypes.string,
  hideResetAccountOption: PropTypes.bool,
  clientId: PropTypes.string,
  loginFrameUrl: PropTypes.string,
  renderWidgetFunction: PropTypes.func,
  showTermsTextOnLogin: PropTypes.bool,
  showTermsText: PropTypes.bool,
  userAppTypeMismatchError: PropTypes.string,
  emailTokenLoggedInUserMismatchError: PropTypes.string,
  userNamespace: PropTypes.string,
  manageProfileError: PropTypes.bool,
  oktaOrgId: PropTypes.string,
  showSetPasswordDescription: PropTypes.bool,
  emailTokenUserRegisteredWarning: PropTypes.bool,
  skipPatientSummary: PropTypes.bool,
  appType: PropTypes.string,
  hideRememberMe: PropTypes.bool,
  userMfaPolicyTier: PropTypes.string,
  contextMfaPolicyTier: PropTypes.string,
};
